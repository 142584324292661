import { Component, OnInit } from "@angular/core";
import { BlogService } from "src/app/services/blog.service";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { ViewEncapsulation } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-articulo-blog",
  templateUrl: "./articulo-blog.component.html",
  styleUrls: ["./articulo-blog.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ArticuloBlogComponent implements OnInit {
  content;
  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cookie: CookieService,
private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
this.spinner.show()
    this.blogService.getById(this.cookie.get("selectedBlog")).then(res => {
      if (res[0].contenido){
 this.content = res[0].contenido;
this.spinner.hide();
}
    });
  }

  sanitize(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
