import { Component, OnInit, OnDestroy } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";
import { VideoService } from "src/app/services/video.service";

declare var $: any;


@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"]
})
export class VideoComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {

    if( $("#videos").data('lightGallery')) $("#videos").data('lightGallery').destroy(true);
  
// Go to third slide
// Index starts from 0

  }
  prueba
  videos
  video

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private videoService: VideoService
  ) {}

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    
    this.videoService.getAllVideos().then(res => {
      this.videos = res
        $(document).ready(function() {
         $("#videos").lightGallery();
         
        });
    })
  }

  setVideo(video: any) {
    this.video = video;
  }
}
