import { Component, OnInit } from "@angular/core";
import { DisenoService } from "../../../services/diseno.service";
import { Diseno } from "../../../interface/diseno.interface";
import { UploadFilesService } from "../../../services/upload-files.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-diseno-admin",
  templateUrl: "./diseno-admin.component.html",
  styleUrls: ["../fotografia-admin/fotografia-admin.component.scss"]
})
export class DisenoAdminComponent implements OnInit {
  listaItems: Diseno[];
  selectedItem: Diseno;
  constructor(
    private disenoService: DisenoService,
    private uploadService: UploadFilesService,
    private router: Router,
    private cookie: CookieService
  ) {
    this.selectedItem = {
      _id: "",
      url: "",
      nombre: "",
      descripcion: "",
      category: "",
      creationDate: "",
      order: null,
      casoReal: ""
    };
  }

  ngOnInit() {
    if (!this.cookie.get("token")) this.router.navigate(["../"]);

    this.disenoService
      .getAllDesings()
      .then(res => {
        this.listaItems = res;
      })
      .catch(err => console.log(err));
  }

  selectItem(item): void {
    this.selectedItem = item;
  }
  deleteItem(item) {
    this.uploadService.deleteFile(item, "diseno").then(res => {
      setTimeout(() => {
        console.log(res);
        this.disenoService
          .getAllDesings()
          .then(res => {
            this.listaItems = res;
          })
          .catch(err => console.log(err));
      }, 10);
    });
  }
  actualizar(event) {
    setTimeout(() => {
      this.disenoService.getAllDesings().then(res => {
        this.listaItems = res;
      });
    }, 10);
  }
}
