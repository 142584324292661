import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { spanish } from "../../../../assets/textos/spanish.js";
import * as $ from "jquery";
import mixitup from "mixitup";
import { Gallery, GalleryItem } from "@ngx-gallery/core";
import { Router, NavigationEnd } from "@angular/router";
import { FotografiaService } from "src/app/services/fotografia.service.js";

@Component({
  selector: "app-fotografia",
  templateUrl: "./fotografia.component.html",
  styleUrls: ["./fotografia.component.scss"]
})
export class FotografiaComponent implements OnInit, OnDestroy, AfterViewInit {
  mixer: any;
  menu: any[];
  fotos: any
  

  items: GalleryItem[];

  constructor(
    public gallery: Gallery,
    private router: Router,
    private fotografiaService: FotografiaService
  ) {}

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    // this.mixer.destroy();
  }

  ngOnInit() {
    this.fotografiaService.getAllImages().then(res => {
      this.fotos = res
      this.createMixitup();

    })
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.menu = spanish.filtros.options;

  }

  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }

  createMixitup() {
    // this.mixer = mixitup("#fotografias", {
    //   selectors: {
    //     control: "[data-mixitup-control]"
    //   }
    // });
  }

}
