import { Component, OnInit, ViewChild } from "@angular/core";
import Quill from "quill";

import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageResize", ImageResize);

import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormControlName,
  Validators
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { BlogService } from "../../../services/blog.service";
import { ActivatedRoute, Router } from "@angular/router";

import * as $ from 'jquery'
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-new-post",
  templateUrl: "./new-post.component.html",
  styleUrls: [
    "./new-post.component.scss",
    "../../components/upload-modal/upload-modal.component.scss"
  ]
})

export class NewPostComponent implements OnInit {

  titulo : string =""
  modules = {};
  form: FormGroup;
  uploadBlog: FormGroup;
  file: File;
  show: boolean = true;
  id: String = null;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private blogService: BlogService,
    private route: ActivatedRoute,
    private router: Router,
    private cookie: CookieService
  ) {
    this.modules = {
      formula: true,
      imageResize: {},
      syntax: true,
      toolbar: [["image"]]
    };
  }

  ngOnInit() {
    if(!this.cookie.get("token")) this.router.navigate(["../"])

    this.startForms();

    this.route.params.subscribe(params => {
      if (params.id) {
        this.blogService
          .getById(params.id)
          .then(res => {
            this.show = false;
            this.id = params.id;
            this.form = this.fb.group({
              html: new FormControl(res[0].contenido)
            });

            this.uploadBlog = this.fb.group({
              titulo: new FormControl(res[0].titulo, Validators.required)
            });
          })
          .catch(err => console.log(err));
      }
    });
  }

  startForms() {
    this.form = this.fb.group({
      html: new FormControl()
    });

    this.uploadBlog = this.fb.group({
      titulo: new FormControl("", Validators.required)
    });
  }

  addBindingCreated(quill) {
   
  }

  byPassHTML(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  update() {
    let payload = {
      contenido: this.form.value.html,
      titulo: this.uploadBlog.value.titulo,
      id: this.id
    };
    this.blogService
      .updateArticulo(payload)
      .then(res => {
        setTimeout(() => {
          this.blogService
            .getById(this.id)
            .then(res => {
              this.form = this.fb.group({
                html: new FormControl(res[0].contenido)
              });

              this.uploadBlog = this.fb.group({
                titulo: new FormControl(res[0].titulo, Validators.required)
              });
            })
            .catch(err => console.log(err));
        }, 10);
      })
      .catch(err => console.log(err));
  }

  submitForm() {
    this.blogService
      .postArticulo(this.setFormData())
      .then(res => {
        this.uploadBlog.reset();
        this.form.reset();
      })
      .catch(err => console.log(err));
  }

  setFormData() {
    let fd = new FormData();
    fd.append("file", this.file[0]);
    fd.append("titulo", this.uploadBlog.value.titulo);
    fd.append("contenido", this.form.value.html);

    return fd;
  }

  onFileChange(event) {
    this.file = event.target.files;
  }
}
