import { Component, OnInit } from "@angular/core";
import { VideoService } from "../../../services/video.service";
import { Video } from "../../../interface/video.interface";
import { UploadFilesService } from "../../../services/upload-files.service";
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-video-admin",
  templateUrl: "./video-admin.component.html",
  styleUrls: ["../fotografia-admin/fotografia-admin.component.scss"]
})
export class VideoAdminComponent implements OnInit {
  listaItems: Video[];
  selectedItem: Video;
  constructor(
    private videoService: VideoService,
    private uploadService: UploadFilesService,
    private cookie: CookieService,
    private router: Router
  ) {
    this.selectedItem = {
      _id: "",
      urlVideo: "",
      nombre: "",
      creationDate: "",
      order: null,
      urlMiniatura: ""
    };
  }

  ngOnInit() {
    if(!this.cookie.get("token")) this.router.navigate(["../"])

    this.videoService.getAllVideos().then(res => {
      this.listaItems = res;
    });
  }

  selectItem(item): void {
    this.selectedItem = item;
  }
  deleteItem(item) {
    this.uploadService.deleteFile(item, "video").then(res => {
      setTimeout(() => {
        this.videoService.getAllVideos().then(res => {
          this.listaItems = res;
        });
      }, 10);
    });
  }
  actualizar(event) {
    setTimeout(() => {
      this.videoService.getAllVideos().then(res => {
        this.listaItems = res;
      });
    }, 10);
  }
}
