import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Blog } from "../interface/blog.interfate";

@Injectable({
  providedIn: "root",
})
export class BlogService {
  uri: any = `https://visualquality.es:4000/api/blog`;
  constructor(private http: HttpClient) {}

  postArticulo(data): Promise<any> {
    let header = new HttpHeaders();

    header.append("Content-Type", "multipart/form-data");
    const req = new HttpRequest("POST", this.uri, data, { headers: header });

    return this.http.request(req).toPromise();
  }

  getAll(): Promise<Blog[]> {
    return this.http.get<Blog[]>(this.uri).toPromise();
  }

  getPartial(number) {
    return this.http.get(`${this.uri}/${number}`).toPromise();
  }

  delete(item): Promise<any> {
    let url = `${this.uri}/${item._id}`;
    return this.http.delete(url).toPromise();
  }

  getById(id): Promise<Blog> {
    let url = `${this.uri}/article/id/${id}`;

    return this.http.get<Blog>(url).toPromise();
  }
  getByName(name): Promise<Blog> {
    let url = `${this.uri}/article/${name}`;

    return this.http.get<Blog>(url).toPromise();
  }

  updateArticulo(data): Promise<Blog> {
    return this.http.put<Blog>(this.uri, data).toPromise();
  }
}
