import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ContactoService {
  constructor(private http: HttpClient) {}

  sendNotification(data): Promise<any> {
    let url = "https://visualquality.es:4000/api/formulario";
    return this.http.post(url, data).toPromise()
  }
}
