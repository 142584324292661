import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  constructor(private coockieService: CookieService, private router: Router) {}

  ngOnInit() {
    this.startJquery();
  }

  startJquery() {
    $(document).ready(function() {
      $("#sidebarCollapse").on("click", function() {
        $("#sidebar").toggleClass("active");
      });
    });
  }

  logout() {
    this.coockieService.delete("token");
    this.router.navigate(["../"])
  }
}
