import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Video } from '../interface/video.interface';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  urlBase: string = "https://visualquality.es:4000/api"
  constructor( private http: HttpClient) { }


  getAllVideos(): Promise<Video[]>{
    let url = `${this.urlBase}/video`
    return this.http.get<Video[]>(url).toPromise()
0  }
}
