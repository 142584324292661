import { Component, OnInit } from "@angular/core";
import { BlogService } from "../../../services/blog.service";
import { Blog } from "../../../interface/blog.interfate";
import { Router, Route } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-blog-admin",
  templateUrl: "./blog-admin.component.html",
  styleUrls: [
    "./blog-admin.component.scss",
    "../fotografia-admin/fotografia-admin.component.scss"
  ]
})
export class BlogAdminComponent implements OnInit {
  listaArticulos: Blog[];
  constructor(
    private blogService: BlogService,
    private router: Router,
    private cookie: CookieService
  ) {}

  ngOnInit() {
    if (!this.cookie.get("token")) this.router.navigate(["../"]);
    this.blogService
      .getAll()
      .then(res => (this.listaArticulos = res))
      .catch(err => console.log(err));
  }

  selectItem(event) {
    this.router.navigate(["/backoffice", "articulo", event._id]);
  }

  deleteItem(item) {
    this.blogService
      .delete(item)
      .then(res => {
        setTimeout(() => {
          this.blogService
            .getAll()
            .then(res => (this.listaArticulos = res))
            .catch(err => console.log(err));
        }, 10);
      })
      .catch(err => console.log(err));
  }

  nuevoArticulo() {
    this.router.navigate(["/backoffice", "articulo"]);
  }
}
