import { Component, OnInit} from "@angular/core";
import {
  ReactiveFormsModule,
  Validators,
  FormGroup,
  FormControl,
  FormBuilder
} from "@angular/forms";
import { ContactoService } from "../../../services/contacto.service";
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: "app-contacto",
  templateUrl: "./contacto.component.html",
  styleUrls: ["./contacto.component.scss"]
})
export class ContactoComponent implements OnInit {
  formularioContacto: FormGroup;
  canSend: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private contactoService: ContactoService,
    private cookieService: CookieService,
    private toastr : ToastrService,
    private router : Router
  ) {
   
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
    
    this.buildForm();
  }

  buildForm() {
    this.formularioContacto = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      email: new FormControl("", [
        Validators.required,
        Validators.email,
        Validators.pattern(
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
        )
      ]),
      asunto: new FormControl("", [Validators.required]),
      mensaje: new FormControl("", [Validators.required])
    });
  }

  cleanForm() {
    this.formularioContacto.reset();
  }

  get f() {
    return this.formularioContacto.controls;
  }

  onSubmit() {



    if (!this.cookieService.check('canSend')) {
      if (!this.formularioContacto.valid) return;


      this.checkCookie()

    }else{
      this.toastr.error("No puede enviar esta solicitud, espere un minuto", "Contacto")
     
    }


    console.log(this.formularioContacto.value);
    this.contactoService
      .sendNotification(this.formularioContacto.value)
      .then(res => {

         this.toastr.success("Solicitud enviada con exito", "Contacto");
        this.cleanForm();
      })
      .catch(err => console.log(err));
  }

  checkCookie() {
    let date = new Date()
    date.setTime(date.getTime() + (60 * 1000));
    if (!this.cookieService.check('canSend')) {
      this.cookieService.set('canSend', 'false', date);
    }
  }
}
