import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-conocenos',
  templateUrl: './conocenos.component.html',
  styleUrls: ['./conocenos.component.scss']
})
export class ConocenosComponent implements OnInit{

  mostrarPlay : boolean = false;
  tiempoVideo : number ;


  // ngAfterViewInit(): void {
  //   setTimeout(() => {
  //     this.tiempoVideo = this.video.nativeElement.duration;
  //     console.log(this.tiempoVideo)
  //     setTimeout(() => {
  //       this.mostrarPlay = true;
  //     }, this.tiempoVideo);
  //   }, 100);
    
    
    
    
  // }

  @ViewChild('video') video : ElementRef;

  constructor(private router : Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
    
  }

  play(){
    this.mostrarPlay = false;
    this.video.nativeElement.currentTime = 0;
    this.video.nativeElement.load();
    this.video.nativeElement.controls = true;

  }

  vidEnded(){

    this.mostrarPlay = true;
  }

}
