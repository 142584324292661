import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Fotografia } from '../interface/fotografia.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FotografiaService {

  urlBase: string = "https://visualquality.es:4000/api"
  constructor( private http: HttpClient) { }


  getAllImages(): Promise<Fotografia[]>{
    let url = `${this.urlBase}/fotografia`
    return this.http.get<Fotografia[]>(url).toPromise()
0  }
}
