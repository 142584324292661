import { Component, OnInit } from "@angular/core";
import { ClientesService } from "../services/clientes.service";

@Component({
  selector: "app-clientes-admin",
  templateUrl: "./clientes-admin.component.html",
  styleUrls: ["../back/pages/fotografia-admin/fotografia-admin.component.scss"]
})
export class ClientesAdminComponent implements OnInit {
  
  foto: any
  constructor(private clientesService: ClientesService) {}


  ngOnInit() {
    this.clientesService
      .getImagen()
      .then(res => {
        this.foto = res
      })
      .catch(err => console.log(err));
  }


  actualizar($event){
    setTimeout(() => {
      this.clientesService.getImagen().then(res => {
        this.foto = res;
      });
    }, 10);
  }

  deleteItem(item){
    this.clientesService.delete(this.foto, "clientes").then(res => {
  
          this.foto = false
     
    });
  }
}
