import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { UserService } from "../../../services/user.service";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"]
})
export class IndexComponent implements OnInit {
  loginForm: FormGroup;
  show: boolean = true;

  constructor(
    private fb: FormBuilder,
    private cookieService: CookieService,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (
      this.cookieService.get("token") != "" ||
      this.cookieService.get("token")
    ) {
      this.show = false;
    }
    this.loginForm = this.fb.group({
      nombre: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  submitForm() {
    this.userService
      .login(this.loginForm.value)
      .then(res => {
        this.cookieService.set("token", res.result);
        this.show = !this.show;
      })
      .catch(err => console.log(err));
  }
}
