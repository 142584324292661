import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";
import { InicioComponent } from "./front/pages/inicio/inicio.component";
import { BlogComponent } from "./front/pages/blog/blog.component";
import { ContactoComponent } from "./front/pages/contacto/contacto.component";
import { FrontComponent } from "./front/front/front.component";
import { FotografiaComponent } from "./front/pages/fotografia/fotografia.component";
import { RscComponent } from "./front/pages/rsc/rsc.component";
import { VideoComponent } from "./front/pages/video/video.component";
import { ClientesComponent } from "./front/pages/clientes/clientes.component";
import { ConocenosComponent } from "./front/pages/conocenos/conocenos.component";
import { AvisoLegalComponent } from "./front/pages/aviso-legal/aviso-legal.component";
import { BackComponent } from "./back/back/back.component";
import { IndexComponent } from "./back/pages/index/index.component";
import { FotografiaAdminComponent } from "./back/pages/fotografia-admin/fotografia-admin.component";
import { DisenoAdminComponent } from "./back/pages/diseno-admin/diseno-admin.component";
import { VideoAdminComponent } from "./back/pages/video-admin/video-admin.component";
import { BlogAdminComponent } from "./back/pages/blog-admin/blog-admin.component";
import { NewPostComponent } from "./back/pages/new-post/new-post.component";
import { PoliticaCookiesComponent } from "./front/pages/politica-cookies/politica-cookies.component";
import { DisenoComponent } from "./front/pages/diseno/diseno.component";
import { ClientesAdminComponent } from "./clientes-admin/clientes-admin.component";
import { ArticuloBlogComponent } from "./front/pages/articulo-blog/articulo-blog.component";
import { DronComponent } from "./front/pages/dron/dron.component";

const routes: Routes = [
  {
    path: "main",
    component: FrontComponent,
    children: [
      { path: "", component: InicioComponent },
      { path: "blog", component: BlogComponent },
      { path: "blog/articulo/:nombre", component: ArticuloBlogComponent },
      { path: "contacto", component: ContactoComponent },
      { path: "fotografia", component: FotografiaComponent },
      { path: "rsc", component: RscComponent },
      { path: "video", component: VideoComponent },
      { path: "clientes", component: ClientesComponent },
      { path: "conocenos", component: ConocenosComponent },
      { path: "aviso-legal", component: AvisoLegalComponent },
      { path: "politica-cookies", component: PoliticaCookiesComponent },
      { path: "diseno-grafico", component: DisenoComponent },
      { path: "dron", component: DronComponent },
    ],
  },
  {
    path: "backoffice",
    component: BackComponent,
    children: [
      { path: "", component: IndexComponent },
      { path: "fotografia", component: FotografiaAdminComponent },
      { path: "diseno", component: DisenoAdminComponent },
      { path: "video", component: VideoAdminComponent },
      { path: "blog", component: BlogAdminComponent },
      { path: "articulo", component: NewPostComponent },
      { path: "articulo/:id", component: NewPostComponent },
      { path: "clientes", component: ClientesAdminComponent },
    ],
  },
  { path: "", redirectTo: "main", pathMatch: "full" },
  { path: "**", redirectTo: "/", pathMatch: "full" },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  // ...any other options you'd like to use
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
