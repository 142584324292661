import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./front/components/navbar/navbar.component";
import { InicioComponent } from "./front/pages/inicio/inicio.component";
import { BlogComponent } from "./front/pages/blog/blog.component";
import { ContactoComponent } from "./front/pages/contacto/contacto.component";
import { FrontComponent } from "./front/front/front.component";
import { FotografiaComponent } from "./front/pages/fotografia/fotografia.component";
import { DisenoComponent } from "./front/pages/diseno/diseno.component";
// import { GalleryModule } from  '@ngx-gallery/core';
// import { LightboxModule } from  '@ngx-gallery/lightbox';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BackComponent } from "./back/back/back.component";
import { IndexComponent } from "./back/pages/index/index.component";
import { SidebarComponent } from "./back/components/sidebar/sidebar.component";
import { FotografiaAdminComponent } from "./back/pages/fotografia-admin/fotografia-admin.component";
import { UploadModalComponent } from "./back/components/upload-modal/upload-modal.component";
import { RscComponent } from "./front/pages/rsc/rsc.component";
import { CrystalLightboxModule } from "@crystalui/angular-lightbox";
import { VideoComponent } from "./front/pages/video/video.component";
import { SafePipe } from "./front/pipes/safe.pipe";
import { ClientesComponent } from "./front/pages/clientes/clientes.component";
import { AngularFullpageModule } from "@fullpage/angular-fullpage";
import { ConocenosComponent } from "./front/pages/conocenos/conocenos.component";
import { AvisoLegalComponent } from "./front/pages/aviso-legal/aviso-legal.component";
import { UpdateComponent } from "./back/components/update/update.component";
import { VideoAdminComponent } from "./back/pages/video-admin/video-admin.component";
import { DisenoAdminComponent } from "./back/pages/diseno-admin/diseno-admin.component";
import { BlogAdminComponent } from "./back/pages/blog-admin/blog-admin.component";
import { NewPostComponent } from "./back/pages/new-post/new-post.component";
import { QuillModule } from "ngx-quill";
import { FooterComponent } from './front/components/footer/footer.component';
import { BlogService } from './services/blog.service';
import { ContactoService } from './services/contacto.service';
import { DisenoService } from './services/diseno.service';
import { FotografiaService } from './services/fotografia.service';
import { UploadFilesService } from './services/upload-files.service';
import { VideoService } from './services/video.service';
import { PoliticaCookiesComponent } from './front/pages/politica-cookies/politica-cookies.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { ClientesAdminComponent } from './clientes-admin/clientes-admin.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ArticuloBlogComponent } from './front/pages/articulo-blog/articulo-blog.component';
import {LazyLoadDirective} from './front/lazyLoading'

import { NgxSpinnerModule } from 'ngx-spinner';
import { DronComponent } from './front/pages/dron/dron.component';


const cookieConfig: NgcCookieConsentConfig = {

  "cookie": {
    "domain": "localhost",
    "name": "consent"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "Este sitio web utiliza cookies para garantizar que obtenga la mejor experiencia en nuestro sitio web.",
    "dismiss": "Acepto!",
    "deny": "Rechazar",
    "link": "Leer mas",
    "href": "https://visualquality.es/main/politica-cookies",
    "policy": "Cookie Policy"
  }

};


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    InicioComponent,
    BlogComponent,
    ContactoComponent,
    FrontComponent,
    FotografiaComponent,
    BackComponent,
    IndexComponent,
    SidebarComponent,
    FotografiaAdminComponent,
    UploadModalComponent,
    RscComponent,
    VideoComponent,
    SafePipe,
    ClientesComponent,
    ConocenosComponent,
    AvisoLegalComponent,
    UpdateComponent,
    VideoAdminComponent,
    DisenoAdminComponent,
    BlogAdminComponent,
    NewPostComponent,
    FooterComponent,
    PoliticaCookiesComponent,
    DisenoComponent,
    ClientesAdminComponent,
    SafeHtmlPipe,
    ArticuloBlogComponent,
    LazyLoadDirective,
    DronComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CrystalLightboxModule,
    AngularFullpageModule,
    QuillModule.forRoot({
      modules: {toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
    
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
    
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
    
        ['clean'],                                         // remove formatting button
    
        ['link', 'image', 'video']                         // link and image, video
      ],
        imageResize: {
          displaySize: true
        }
      }
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ToastrModule.forRoot(),
    NgxSpinnerModule
  ],
  providers: [
    BlogService,
    ContactoService,
    DisenoService,
    FotografiaService,
    UploadFilesService,
    VideoService,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
