import { Component, OnInit, OnDestroy, AfterViewInit, Sanitizer } from "@angular/core";
import { spanish } from "../../../../assets/textos/spanish.js";
import * as $ from "jquery";
import mixitup from "mixitup";
import { Gallery, GalleryItem } from "@ngx-gallery/core";
import { Router, NavigationEnd } from "@angular/router";
import { DisenoService } from "src/app/services/diseno.service.js";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "app-diseno",
  templateUrl: "./diseno.component.html",
  styleUrls: ["./diseno.component.scss"]
})
export class DisenoComponent implements OnInit, OnDestroy, AfterViewInit {
  mixer: any;
  menu: any[];
  fotos: any 

  items: GalleryItem[];

  galleryItem: any;

  constructor(
    public gallery: Gallery,
    private router: Router,
    private disenoService: DisenoService,
    private sanizitation: DomSanitizer
  ) {

    this.galleryItem= {
      url : '',
      casoReal : ''
    }

  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

 

    this.menu = spanish.filtros.options;
    this.disenoService.getAllDesings().then(res => this.fotos = res)
  }

  basicLightboxExample() {
    // this.gallery.ref().load(this.items);
  }

  setGallery(item: any) {
    this.galleryItem = item;
  }
}
