import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import * as $ from 'jquery';
import { DomSanitizer} from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  @ViewChild('video') video : ElementRef;
  scroll : number;
  scrollHecho : boolean = false;


   videos = {
      img : 'video.jpg',
      imgIcon: 'ICONO_VIDEO_LINEA.png',
      texto: 'Producción de Vídeo',
      url: '/main/video'
    }
   diseno = {
      img : 'diseño.jpg',
      imgIcon: 'ICONO_DISEÑO_LINEA.png',
      texto: 'Diseño Gráfico',
      url: '/main/diseno-grafico'
    }
    fotografia = {
      img : 'fotografia.jpg',
      imgIcon: 'ICONO_FOTO_LINEA.png',
      texto: 'Fotografía',
      url: '/main/fotografia'
    }


  constructor(private _sanitizer: DomSanitizer, public router : Router) {

    setTimeout(()=>{
      this.video.nativeElement.muted= true;
      this.video.nativeElement.play();
    },300)

   }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
    
  }


  moveToSection(){
    var div = $('#informacion');
    var pos = div.offset().top;
    $('html, body').animate({scrollTop:pos},500); 
  }

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    this.scroll= document.documentElement.scrollTop;
    if(this.scroll > 0 && !this.scrollHecho){
      this.moveToSection();
      this.scrollHecho= true;
    }
    let elem1 = $('#texto1'); // obtengo el elemento por id
    this.isElementVisible(elem1) ? elem1.addClass('texto') : elem1.removeClass('texto'); // si es visible agrego la class, de lo contrario la remuevo

    let elem2 = $('#texto2'); // obtengo el elemento por id
    this.isElementVisible(elem2) ? elem2.addClass('texto') : elem2.removeClass('texto'); // si es visible agrego la class, de lo contrario la remuevo

    let elem3 = $('#texto3'); // obtengo el elemento por id
    this.isElementVisible(elem3) ? elem3.addClass('texto') : elem3.removeClass('texto'); // si es visible agrego la class, de lo contrario la remuevo

    // let logo = $('.logo'); // obtengo el elemento por id
    // this.isElementVisible(logo) ? logo.addClass('visible') : logo.removeClass('visible'); // si es visible agrego la class, de lo contrario la remuevo




    let svg1 = $('.logo1 path'); // obtengo el elemento por id
    this.isElementVisible(svg1) ? svg1.addClass('visible') : svg1.removeClass('visible'); // si es visible agrego la class, de lo contrario la remuevo
    this.isElementVisible(svg1) ? svg1.addClass('anim') : svg1.removeClass('anim'); // si es visible agrego la class, de lo contrario la remuevo

    let svg2 = $('.logo2 path'); // obtengo el elemento por id
    this.isElementVisible(svg2) ? svg2.addClass('visible') : svg2.removeClass('visible'); // si es visible agrego la class, de lo contrario la remuevo
    this.isElementVisible(svg2) ? svg2.addClass('anim') : svg2.removeClass('anim'); // si es visible agrego la class, de lo contrario la remuevo

    let svg3 = $('.logo3 path'); // obtengo el elemento por id
    this.isElementVisible(svg3) ? svg3.addClass('visible') : svg3.removeClass('visible'); // si es visible agrego la class, de lo contrario la remuevo
    this.isElementVisible(svg3) ? svg3.addClass('anim') : svg3.removeClass('anim'); // si es visible agrego la class, de lo contrario la remuevo

  }


  isElementVisible(elem){
    let viewScrollTop = $(window).scrollTop(); // distancia de scroll superior
    let viewBottom = viewScrollTop + $(window).height(); // distancia de scroll + el alto actual de window (lo no visible por scroll + lo visible)
    let topElemD = $(elem).offset().top; // distancia desde el elemento hasta el tope superior del viewport
    return (topElemD < viewBottom);
 }


}
