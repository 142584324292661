import { Component, OnInit, HostListener } from '@angular/core';
import { spanish } from '../../../../assets/textos/spanish.js';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menu: any;
  scroll: number = 0;
  url: string = "";
  esInicio: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private route: Router) {
    

    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = this.activatedRoute.snapshot.firstChild.routeConfig.path;
        this.checkInicio();
      } else {
        this.url = this.activatedRoute.snapshot.firstChild.routeConfig.path;
        this.checkInicio();
      }
    });


  }

  ngOnInit() {
    this.menu = spanish.navbar.options;




  }



  checkInicio() {
    this.url != '' ? this.esInicio = false : this.esInicio = true;
  }



  @HostListener('window:scroll', ['$event']) onScroll(event) {
    if (this.esInicio) {
      this.scroll = document.documentElement.scrollTop;
    }

  }






}
