import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Diseno } from '../interface/diseno.interface';
@Injectable({
  providedIn: 'root'
})
export class DisenoService {

  urlBase: string = "https://visualquality.es:4000/api"
  constructor( private http: HttpClient) { }


  getAllDesings(): Promise<Diseno[]>{
    let url = `${this.urlBase}/diseno`
    return this.http.get<Diseno[]>(url).toPromise()
0  }
}
