export const spanish= {
    navbar:{
        options:{
            inicio:{ 
                title: 'inicio',
                url: ''
            },
            nosotros: {
                title: 'nosotros',
                url: '',
                options: [
                    {
                        title: 'conócenos',
                        url: 'conocenos'
                    },
                    {
                        title: 'rsc',
                        url: 'rsc'
                    },
                    {
                        title: 'clientes',
                        url: 'clientes'
                    },
                    {
                        title: 'aviso legal',
                        url: 'aviso-legal'
                    }   ,
                    {
                        title: 'política cookies',
                        url: 'politica-cookies'
                    }                          
               ]
            },
            portfolio: {
                title: 'portfolio',
                url:'',
                options: [
                    {
                        title: 'vídeo',
                        url: 'video'
                    },
                    {
                        title: 'diseño gráfico',
                        url: 'diseno-grafico'
                    },
                    {
                        title: 'fotografía',
                        url:'fotografia'       
                    }                
               ]
            },
            dron: {
                title: 'dron',
                url: 'dron'
            },
          
            contacto: {
                title: 'contacto',
                url: 'contacto'
            }
        }
    },
    filtros : {
        options:[
            'sesiones','productos','sports','publicitaria','bodas',
        ]
    }
}