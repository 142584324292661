import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class UploadFilesService {
  constructor(private http: HttpClient) {}

  postFile(item, url): Promise<any> {
    let uri = `https://visualquality.es:4000/api/${url}`;
    let header = new HttpHeaders();

    header.append("Content-Type", "multipart/form-data");
    const req = new HttpRequest("POST", uri, item, { headers: header });

    return this.http.request(req).toPromise();
  }

  deleteFile(item, url): Promise<any> {
    let uri = `https://visualquality.es:4000/api/${url}/${item._id}`;
    return this.http.delete(uri).toPromise();
  }

  updateFile(item, url) {
    let uri = `https://visualquality.es:4000/api/${url}`;
    this.http
      .put(uri, item)
      .toPromise()
      .then(res => {})
      .catch(err => console.log(err));
  }
}
