import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UploadFilesService } from "../../../services/upload-files.service";

@Component({
  selector: "app-update",
  templateUrl: "./update.component.html",
  styleUrls: ["../upload-modal/upload-modal.component.scss"]
})
export class UpdateComponent implements OnInit {
  @Input() item: any;
  @Input() modo: string
  @Output() actualizar = new EventEmitter();

  updateFiles: FormGroup;
  file: File;
  itemValues: any;
  titulo : string =""

  constructor(
    private fb: FormBuilder,
    private uploadService: UploadFilesService
  ) {}

  ngOnInit() {
    this.createForm();
    this.itemValues = this.item;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.itemValues = this.item;
    this.createForm();
  }
  createForm() {
    if(this.modo != "video"){
      this.updateFiles = this.fb.group({
        nombre: [this.itemValues.nombre, Validators.required],
        descripcion: [this.itemValues.descripcion, Validators.required],
        category: [this.itemValues.category, Validators.required],
        order: [this.itemValues.order, [Validators.required, Validators.pattern("^[0-9]*$")]],
        _id: [this.itemValues._id]
      });
    } else {
      this.updateFiles = this.fb.group({
        nombre: [this.itemValues.nombre, Validators.required],
        urlVideo: [this.itemValues.urlVideo, Validators.required],
        order: [this.itemValues.order, [Validators.required, Validators.pattern("^[0-9]*$")]],
        _id: [this.itemValues._id]
      });
    }
  }

  submitForm() {
    this.uploadService.updateFile(this.updateFiles.value, this.modo);
    this.actualizar.emit("")
  }

  setFormData() {
  
  }

  onFileChange(event) {
    this.file = event.target.files;
  }
}
