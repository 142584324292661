import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  url: string ="https://visualquality.es:4000/api/clientes"

  constructor(private http: HttpClient) { }


  getImagen(): Promise<any>{
    return this.http.get(this.url).toPromise()
  }

  post(data): Promise<any>{
    return this.http.post(this.url, data).toPromise()
  }

  delete(data, url): Promise<any>{
    let uri = `${this.url}/${data[0]._id}`
    return this.http.delete(uri, data).toPromise()
  }
}
