import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-dron",
  templateUrl: "./dron.component.html",
  styleUrls: ["./dron.component.scss"],
})
export class DronComponent implements OnInit {
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }
  innerWidth;
  constructor(
    private cookieService: CookieService,

    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.innerWidth = window.innerWidth;
  }
}
