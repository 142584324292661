import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { BlogService } from "src/app/services/blog.service";
import { ViewEncapsulation } from "@angular/core";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],

  encapsulation: ViewEncapsulation.Emulated,
})
export class BlogComponent implements OnInit, AfterViewInit {
  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    this.requestMore();
  }

  entradas: any[];
  secciones: any[];
  number: number = 0;
  total: number = 0;
  seccionesIndex: number = 0;
  @ViewChild("fullpageRef") fp_directive: ElementRef;
  config;
  fullpage_api;

  ngAfterViewInit() {}

  constructor(
    private blogService: BlogService,
    private cookie: CookieService,
    private router: Router
  ) {
    this.config = {
      licenseKey: environment.lisence_key,
      menu: "#menu",
      navigation: true,
      autoScrolling: false,
      fitToSection: true,
    };

    // for more details on config options please visit fullPage.js docs
  }

  ngOnInit() {
    this.blogService.getPartial(this.number).then((res) => {
      this.entradas = [...res["resultPartial"]];
      this.total = res["total"];
      for (let index = 0; index < this.total - 2; index++) {
        this.entradas.push([]);
      }
      this.generateSecciones();
    });
  }

  requestMore() {
    this.number += 2;
    if (this.number <= this.total - 2) {
      this.blogService.getPartial(this.number).then((res) => {
        if (this.secciones) {
          this.secciones[this.seccionesIndex +1 ].entradas = res["resultPartial"];
          this.seccionesIndex ++
        }
        this.entradas.push(...res["resultPartial"]);
      });


      
    }
  }

  generateSecciones() {
    this.secciones = [];

    let aux = this.entradas.length / 2;
    var cont = 0;
    var num = 0;

    for (let i = 0; i < aux; i++) {
      cont = 0;
      this.secciones.push({
        titulo: "num " + i,
        entradas: [],
      });
      for (let f = num; f < this.entradas.length; f++) {
        if (cont === 2) break;
        this.secciones[i].entradas.push(this.entradas[f]);
        cont++;
        num++;
      }
    }
    console.log(this.secciones);
  }
  selectBlog(entrada) {
    this.cookie.set("selectedBlog", entrada._id);
  }
}
