import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url: string
  constructor(private http: HttpClient) {
    this.url = "https://visualquality.es:4000/api/login"
   }

  login(data): Promise<any>{
    return this.http.post(this.url, data).toPromise()
  }

}
