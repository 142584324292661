import { Component, OnInit } from "@angular/core";
import { FotografiaService } from "../../../services/fotografia.service";
import { Fotografia } from "../../../interface/fotografia.interface";
import { UploadFilesService } from "../../../services/upload-files.service";
declare var $: any;
import * as $ from "jquery";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
@Component({
  selector: "app-fotografia-admin",
  templateUrl: "./fotografia-admin.component.html",
  styleUrls: ["./fotografia-admin.component.scss"]
})
export class FotografiaAdminComponent implements OnInit {
  listaItems: Fotografia[];
  selectedItem: Fotografia;
  constructor(
    private fotografiaService: FotografiaService,
    private uploadService: UploadFilesService,
    private cookie: CookieService,
    private router: Router
  ) {
    this.selectedItem = {
      _id: "",
      url: "",
      nombre: "",
      descripcion: "",
      category: "",
      creationDate: "",
      order: null
    };
  }

  ngOnInit() {
    if (!this.cookie.get("token")) this.router.navigate(["../"]);

    this.fotografiaService.getAllImages().then(res => {
      this.listaItems = res;
    });
  }

  selectItem(item): void {
    this.selectedItem = item;
  }

  deleteItem(item) {
    this.uploadService.deleteFile(item, "fotografia").then(res => {
      setTimeout(() => {
        this.fotografiaService.getAllImages().then(res => {
          this.listaItems = res;
        });
      }, 10);
    });
  }

  actualizar(event) {
    setTimeout(() => {
      this.fotografiaService.getAllImages().then(res => {
        this.listaItems = res;
      });
    }, 10);
  }
}
