import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray
} from "@angular/forms";
import * as $ from "jquery";
import { UploadFilesService } from "../../../services/upload-files.service";

@Component({
  selector: "app-upload-modal",
  templateUrl: "./upload-modal.component.html",
  styleUrls: ["./upload-modal.component.scss"]
})
export class UploadModalComponent implements OnInit {
  @Input() titulo: string ;
  @Input() extensiones: string;
  @Input() modo: string;
  @Output() actualizar = new EventEmitter();

  

  uploadFiles: FormGroup;
  file: File;
  casoReal: File

  constructor(
    private fb: FormBuilder,
    private uploadService: UploadFilesService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    if(this.modo != "video" ){
      this.uploadFiles = this.fb.group({
        nombre: ["", Validators.required],
        descripcion: ["", Validators.required],
        category: ["", Validators.required],
        order: ["", [Validators.required, Validators.pattern("^[0-9]*$")]]
      });

    } else {
      this.uploadFiles = this.fb.group({
        nombre: ["", Validators.required],
        urlVideo: ["", Validators.required],
        order: ["",[ Validators.required, Validators.pattern("^[0-9]*$")]]
      });
    }
  }

  submitForm() {
    this.uploadService.postFile(this.setFormData(), this.modo).then(res => {
      this.actualizar.emit("")
      this.uploadFiles.reset()
    }).catch(err => console.log(err));
  }

  setFormData() {
    if(this.modo == "fotografia"){
      let fd = new FormData();
      fd.append("file", this.file[0]);
      fd.append("nombre", this.uploadFiles.value.nombre);
      fd.append("descripcion", this.uploadFiles.value.descripcion);
      fd.append("category", this.uploadFiles.value.category);
      fd.append("order", this.uploadFiles.value.order )
  
      return fd;
    } 

    if(this.modo == "diseno"){
      let fd = new FormData();
      fd.append("file", this.file[0]);
      fd.append("casoRealFile", this.casoReal[0]);
      fd.append("nombre", this.uploadFiles.value.nombre);
      fd.append("descripcion", this.uploadFiles.value.descripcion);
      fd.append("category", this.uploadFiles.value.category);
      fd.append("order", this.uploadFiles.value.order )
  
      return fd;
    } 

    if(this.modo == "video") {
      let fd = new FormData();
      fd.append("file", this.file[0]);
      fd.append("nombre", this.uploadFiles.value.nombre);
      fd.append("urlVideo", this.uploadFiles.value.urlVideo);
      fd.append("order", this.uploadFiles.value.order )
  
      return fd;
    }
    if(this.modo == "clientes"){
      let fd = new FormData();
      fd.append("file", this.file[0]);
  
      return fd;
    } 
  }
  onFileChange(event) {
    this.file = event.target.files;
  }

  changeCasoReal(event){
    this.casoReal = event.target.files;

  }
}
